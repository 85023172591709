<div class="spinner spinner-full-screen fade-in" [style]="style ? style : ''">
    <div
        *ngIf="texto"
        class="spinner-text"
        [ngClass]="textoIntermitente ? 'text-blink' : ''">
        {{texto}}
    </div>
    <mat-spinner *ngIf="!determinado"></mat-spinner>
    <mat-spinner *ngIf="determinado"></mat-spinner>
</div>
